<template>
	<div style="height: 100vh" class="m-5">
		<div class="columns is-centered is-vcentered">
			<div class="column is-half" style="height: 100%">
				<div class="card">
					<div class="card-header">
						<div class="card-header-title has-text-white is-centered">
							<img width="300" :src="`/logos/${site}/logo.svg`" />
						</div>
					</div>
					<div class="card-content is-centered">
						<p class="mb-4 p-3 has-text-centered has-background-success has-text-white">İşlem Başarılı</p>
						<div class="is-flex is-justify-content-end">
							<b-button type="is-danger" expanded @click="windowClose" size="is-small">Kapat</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
export default {
	name: "PaymentSuccess",
	components: { CardComponent },
	mounted() {
		const html = document.getElementsByTagName("html")[0];
		html.classList = [];
	},
};
</script>
